import { FilterObjectType, serializeIdOfFilter } from "src/components/table/filters/table-filters";
import { generateURLSearchParams } from "src/components/table/hooks/use-search-filter-params";
import { FilterOperator } from "src/types/filters/filters";

const taskStatusData = {
  name: "status",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In
};

const taskTypeData = {
  name: "type",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.In
};

const taskSRelatedToReservationData = {
  name: "relatedTo",
  type: FilterObjectType.ADDITIONAL,
  operator: FilterOperator.Equality
};

export const DEFAULT_TASK_SEARCH_FILTERS = {
  [serializeIdOfFilter(taskStatusData)]: {
    ...taskStatusData,
    value: "TODO,IN_PROGRESS"
  },
  [serializeIdOfFilter(taskTypeData)]: {
    ...taskTypeData,
    value: "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,SHOP_ORDER"
  }
};

export const DEFAULT_TASK_SEARCH_FILTERS_WITH_FOOD_AND_BEVERAGE_ENABLED = {
  [serializeIdOfFilter(taskStatusData)]: {
    ...taskStatusData,
    value: "TODO,IN_PROGRESS"
  },
  [serializeIdOfFilter(taskTypeData)]: {
    ...taskTypeData,
    value: "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,FOOD_AND_BEVERAGE_ORDER,SHOP_ORDER"
  }
};

export const generateDefaultTaskTableURLSearchParams = (foodAndBeverageEnabled: boolean) =>
  generateURLSearchParams(
    foodAndBeverageEnabled
      ? DEFAULT_TASK_SEARCH_FILTERS_WITH_FOOD_AND_BEVERAGE_ENABLED
      : DEFAULT_TASK_SEARCH_FILTERS
  );

export const generateTaskRelatedToQueryParams = (
  reservationId: string,
  foodAndBeverageEnabled: boolean
) => {
  return generateURLSearchParams({
    [serializeIdOfFilter(taskSRelatedToReservationData)]: {
      ...taskSRelatedToReservationData,
      value: reservationId
    },
    [serializeIdOfFilter(taskTypeData)]: {
      ...taskTypeData,
      value: foodAndBeverageEnabled
        ? "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,FOOD_AND_BEVERAGE_ORDER,SHOP_ORDER"
        : "MANUAL_TASK,SERVICE_TASK,AUTOMATED_TASK,GUEST_TASK,SHOP_ORDER"
    }
  });
};
